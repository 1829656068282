<template>
  <div>
    <img class="banner d-lg-block d-none" src="~assets/images/img/searchBanner.jpg"/>
    <div class="productDetail">
      <div class="rightTitle">
        <div class="rightTitleValue d-lg-block d-flex align-items-center">
          <p class="rightTitleValueC">产品查询</p>
          <p class="rightTitleValueE">Product Search</p>
        </div>
        <div class="rightTitleLinks d-none d-lg-flex">
          <p>当前位置：</p>
          <router-link class="link" to="/home" tag="p">首页</router-link>
          >
          <p class="linkLast">产品查询</p>
        </div>
      </div>
    </div>
    <div class="searchCoat">
      <div class="searchBox">
        <div class="search">
          <input class="searchInput" @blur="inputBlur" @focus="inputFocus" v-model="searchText" placeholder="请输入产品名称关键字，或CAS编码"/>
          <div class="searchIcoCoat" @click="searchBut">
            <img class="searchIco" src="~assets/images/icon/search.png"/>
          </div>
        </div>
        <div class="searchLinks">
          <p class="searchLinkText">例如：</p>
          <router-link  v-for="item in searchLinks" tag="p" :to="{
            path:'/searchcontent',
            query:{
              searchText:item
            }
          }" class="searchLinksItem">{{item}}
          </router-link>
        </div>
      </div>
      <div class="searchList" v-for="item in searchList">
        <p class="searchListTitle">{{item.name}}</p>
        <div class="searchChild" v-for="childItem in item.category_3">
          <p class="searchChildText">{{childItem.name}}：</p>
          <p class="searchChildListItem" v-for="(childItemList,childItemListIndex) in childItem.products">
            {{childItemList.name}}{{childItemListIndex!=childItem.products.length-1?'、':''}}</p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import {getSearch} from 'network/search';
  export default {
    name: "Search",
    data() {
      return {
        cursorStatus:false,
        searchLinks: [],
        searchList:[],
        searchText:'',
      }
    },
    methods:{
      searchBut(){
        this.$router.push({
          path:'/searchcontent',
          query:{
            searchText:this.searchText
          }
        })
      },
      inputBlur(){
        this.cursorStatus=false;
      },
      inputFocus(){
        this.cursorStatus=true;
      }
    },
    created() {
      getSearch().then(res=>{
        this.searchList=res.data.category_2;
        this.searchLinks=res.data.hot_search;
        // console.log(res.data.category_2);
      });
    }
  }
</script>

<style scoped>
  /* 中等屏幕（桌面显示器，大于等于 992px） */
  @media (min-width: 992px) {
    .searchBox {
      margin: 0 10%;
    }
  }

  .searchList {
    margin-bottom: 1.5rem;
  }

  .searchListTitle {
    padding-left: .6rem;
    border-left: 3px solid var(--red);
    font-size: .5rem;
    color: #4c4c4c;
    margin-bottom: .6rem;
  }

  .searchChild {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: .25rem;
    padding-left: .65rem;
  }

  .searchChildText {
    font-size: .4rem;
    color: #a6a6a6;
    margin-right: .6rem;
  }

  .searchChildListItem {
    font-size: .35rem;
    color: #0544a3;
    cursor: pointer;
  }


  .searchCoat {
    padding: 0 var(--marginX);
  }

  .searchBox {
    margin-bottom: 1.1rem;
  }

  .search {
    height: 1.6rem;
    display: flex;
    align-items: center;
    margin-bottom: .4rem;
  }

  .searchInput {
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: .8rem;
    font-size: .35rem;
    flex: 1;
    border: 1px solid #bfbfbf;
    border-radius: 8px 0 0 8px;
    border-right: none;
    color: #666666;
  }

  .searchInput::placeholder {
    color: #bfbfbf;
  }

  .searchIcoCoat {
    border-radius: 0 8px 8px 0;
    height: 100%;
    background-color: var(--red);
    padding: .4rem .8rem;
    z-index: 1;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .searchIco {
    height: 100%;
    width: auto;
  }

  .searchLinks {
    display: flex;
    align-items: center;
    padding-left: .8rem;
    font-size: .35rem;
    color: #bfbfbf;
  }

  .searchLinksItem {
    margin-left: .45rem;
    cursor: pointer;
  }

  .searchLinksItem:hover {
    text-shadow: 0 0 2px #bfbfbf;
  }

  .banner {
    width: 100%;
    height: auto;
  }

  .productDetail {
    padding: 0 var(--marginX);
  }

  .rightTitle {
    padding: 1.5rem 0 .3rem 0;
    margin-bottom: .55rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e2e2e2;
  }

  .rightTitleValueC {
    font-size: .6rem;
    color: #474747;
    margin-right: .4rem;
  }

  .rightTitleValueE {
    font-size: .35rem;
    color: #b2b2b2;
    padding: 0;
  }

  .rightTitleLinks {
    display: flex;
    align-items: center;
    font-size: .35rem;
    color: #b2b2b2;
    cursor: pointer;
  }

  .rightTitleLinks .link:hover {
    color: var(--red);
    transition: color .3s;
  }

  .rightTitleLinks .linkLast {
    color: #737373;
  }

</style>